/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { AddressFragmentDoc, FileFragmentDoc, CustomAddressFragmentDoc } from './CoreFragments.generated';
import { LeadFragmentDoc } from './LeadFragments.generated';
import { NewRatingFragmentDoc } from './RatingFragments.generated';
export const EnergoElectricityPartnerFragmentDoc = gql`
    fragment EnergoElectricityPartner on EnergoElectricityPartner {
  id
  name
  seoName
  logoUrl
  logoSquareUrl
  rating {
    ...NewRating
  }
  descriptionMD
  estimateDefaultProductId
  energoPartnerId
  termsOfServicePreview
}
    ${NewRatingFragmentDoc}`;
export const EnergoGasPartnerFragmentDoc = gql`
    fragment EnergoGasPartner on EnergoGasPartner {
  id
  name
  seoName
  logoUrl
  logoSquareUrl
  rating {
    ...NewRating
  }
  descriptionMD
  termsOfServicePreview
  estimateDefaultProductId
  energoPartnerId
}
    ${NewRatingFragmentDoc}`;
export const EnergoFloorAreaRangeCodebookFragmentDoc = gql`
    fragment EnergoFloorAreaRangeCodebook on EnergoFloorAreaRangeCodebook {
  id
  label: name
}
    `;
export const EnergoIsolationCodebookFragmentDoc = gql`
    fragment EnergoIsolationCodebook on EnergoIsolationCodebook {
  id
  label: name
}
    `;
export const EnergoPersonsCountRangeCodebookFragmentDoc = gql`
    fragment EnergoPersonsCountRangeCodebook on EnergoPersonsCountRangeCodebook {
  id
  label: name
}
    `;
export const EnergoDistributionRateFragmentDoc = gql`
    fragment EnergoDistributionRate on EnergoDistributionRate {
  id
  name
  lowTariff
}
    `;
export const EnergoBreakerFragmentDoc = gql`
    fragment EnergoBreaker on EnergoBreaker {
  id
  name
}
    `;
export const EnergoElectricityProductFragmentDoc = gql`
    fragment EnergoElectricityProduct on EnergoElectricityProduct {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
}
    `;
export const EnergoElectricityResultPriceDetailFragmentDoc = gql`
    fragment EnergoElectricityResultPriceDetail on EnergoElectricityResultPriceDetail {
  priceResult
  priceProductMWhLT
  priceProductMWhHT
  priceProductMonthly
  priceDistributionMWhLT
  priceDistributionMWhHT
  priceDistributionMonthly
  yearlyDistributionPrice
  yearlyPartnerPrice
}
    `;
export const EnergoElectricityResultItemFragmentDoc = gql`
    fragment EnergoElectricityResultItem on EnergoElectricityResultItem {
  id
  partner {
    ...EnergoElectricityPartner
  }
  product {
    ...EnergoElectricityProduct
  }
  priceExpectedDetail {
    ...EnergoElectricityResultPriceDetail
  }
  priceResultDetail {
    ...EnergoElectricityResultPriceDetail
  }
  priceCurrent
  priceExpected
  priceCalculatedAnnually
  priceCalculatedMonthly
  saving
  savingEntirePeriod
  discountToInvoice
  discountToInvoiceWithoutVat
  kalkulatorTip
  maxSaving
  entirePeriodSavingIsMax
}
    ${EnergoElectricityPartnerFragmentDoc}
${EnergoElectricityProductFragmentDoc}
${EnergoElectricityResultPriceDetailFragmentDoc}`;
export const EnergoGasProductFragmentDoc = gql`
    fragment EnergoGasProduct on EnergoGasProduct {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
}
    `;
export const EnergoGasResultPriceDetailFragmentDoc = gql`
    fragment EnergoGasResultPriceDetail on EnergoGasResultPriceDetail {
  priceResult
  priceProductMWh
  priceProductMonthly
  priceDistributionMWh
  priceDistributionMonthly
  yearlyDistributionPrice
  yearlyPartnerPrice
}
    `;
export const EnergoGasResultItemFragmentDoc = gql`
    fragment EnergoGasResultItem on EnergoGasResultItem {
  id
  partner {
    ...EnergoGasPartner
  }
  product {
    ...EnergoGasProduct
  }
  priceExpectedDetail {
    ...EnergoGasResultPriceDetail
  }
  priceResultDetail {
    ...EnergoGasResultPriceDetail
  }
  priceCurrent
  priceExpected
  priceCalculatedAnnually
  priceCalculatedMonthly
  saving
  savingEntirePeriod
  discountToInvoice
  discountToInvoiceWithoutVat
  kalkulatorTip
  maxSaving
  entirePeriodSavingIsMax
}
    ${EnergoGasPartnerFragmentDoc}
${EnergoGasProductFragmentDoc}
${EnergoGasResultPriceDetailFragmentDoc}`;
export const PublicEnergoCalculationFragmentDoc = gql`
    fragment PublicEnergoCalculation on PublicEnergoCalculation {
  id
  uuid
  type
  source
  partner {
    ...EnergoElectricityPartner
  }
  yearlyConsumption
  yearlyConsumptionUnit
  yearlyConsumptionLowTariff
  yearlyConsumptionLowTariffUnit
  yearlyPayment
  gasYearlyPayment
  gasYearlyConsumption
  gasYearlyConsumptionUnit
  gasPartner {
    ...EnergoGasPartner
  }
  gasMonthlyAdvances
  gasUnknownMonthlyAdvances
  gasUsages {
    basicAppliances
    heating
    waterHeating
  }
  monthlyAdvances
  unknownMonthlyAdvances
  electricityUsages {
    basicAppliances
    heating
    waterHeating
  }
  floorAreaRange {
    ...EnergoFloorAreaRangeCodebook
  }
  isolation {
    ...EnergoIsolationCodebook
  }
  personsCountRange {
    ...EnergoPersonsCountRangeCodebook
  }
  distributionRate {
    ...EnergoDistributionRate
  }
  breaker {
    ...EnergoBreaker
  }
  saving
  createdAt
  updatedAt
  lastViewed
  currentProductId
  currentProductName
  currentProductFollowingId
  unknownCurrentProduct
  gasCurrentProductId
  gasCurrentProductName
  gasCurrentProductFollowingId
  gasUnknownCurrentProduct
  standaloneInvoice {
    id
    uuid
  }
  bestResultItem {
    ...EnergoElectricityResultItem
  }
  bestGasResultItem {
    ...EnergoGasResultItem
  }
  individualPriceExpectedMonthly
  individualPriceExpectedMWhHighTariff
  individualPriceExpectedMWhLowTariff
  gasIndividualPriceExpectedMonthly
  gasIndividualPriceExpectedMWh
  individualOfferName
  zip
}
    ${EnergoElectricityPartnerFragmentDoc}
${EnergoGasPartnerFragmentDoc}
${EnergoFloorAreaRangeCodebookFragmentDoc}
${EnergoIsolationCodebookFragmentDoc}
${EnergoPersonsCountRangeCodebookFragmentDoc}
${EnergoDistributionRateFragmentDoc}
${EnergoBreakerFragmentDoc}
${EnergoElectricityResultItemFragmentDoc}
${EnergoGasResultItemFragmentDoc}`;
export const EnergoCalculationFragmentDoc = gql`
    fragment EnergoCalculation on EnergoCalculation {
  id
  uuid
  address {
    ...Address
  }
  type
  source
  partner {
    ...EnergoElectricityPartner
  }
  yearlyConsumption
  yearlyConsumptionUnit
  yearlyConsumptionLowTariff
  yearlyConsumptionLowTariffUnit
  yearlyPayment
  gasYearlyPayment
  gasYearlyConsumption
  gasYearlyConsumptionUnit
  gasPartner {
    ...EnergoGasPartner
  }
  gasMonthlyAdvances
  gasUnknownMonthlyAdvances
  gasUsages {
    basicAppliances
    heating
    waterHeating
  }
  monthlyAdvances
  unknownMonthlyAdvances
  electricityUsages {
    basicAppliances
    heating
    waterHeating
  }
  floorAreaRange {
    ...EnergoFloorAreaRangeCodebook
  }
  isolation {
    ...EnergoIsolationCodebook
  }
  personsCountRange {
    ...EnergoPersonsCountRangeCodebook
  }
  distributionRate {
    ...EnergoDistributionRate
  }
  breaker {
    ...EnergoBreaker
  }
  email
  saving
  createdAt
  updatedAt
  lastViewed
  currentProductId
  currentProductName
  currentProductFollowingId
  unknownCurrentProduct
  gasCurrentProductId
  gasCurrentProductName
  gasCurrentProductFollowingId
  gasUnknownCurrentProduct
  standaloneInvoice {
    id
    uuid
  }
  bestResultItem {
    ...EnergoElectricityResultItem
  }
  bestGasResultItem {
    ...EnergoGasResultItem
  }
  phone
  individualPriceExpectedMonthly
  individualPriceExpectedMWhHighTariff
  individualPriceExpectedMWhLowTariff
  gasIndividualPriceExpectedMonthly
  gasIndividualPriceExpectedMWh
  individualOfferName
  reason
}
    ${AddressFragmentDoc}
${EnergoElectricityPartnerFragmentDoc}
${EnergoGasPartnerFragmentDoc}
${EnergoFloorAreaRangeCodebookFragmentDoc}
${EnergoIsolationCodebookFragmentDoc}
${EnergoPersonsCountRangeCodebookFragmentDoc}
${EnergoDistributionRateFragmentDoc}
${EnergoBreakerFragmentDoc}
${EnergoElectricityResultItemFragmentDoc}
${EnergoGasResultItemFragmentDoc}`;
export const EnergoElectricityCalculationResultCommonFragmentDoc = gql`
    fragment EnergoElectricityCalculationResultCommon on EnergoElectricityCalculationResultCommon {
  priceCurrentAnnually
  priceCurrentMonthly
  consumptionHT
  consumptionLT
}
    `;
export const EnergoElectricityCalculationResultFragmentDoc = gql`
    fragment EnergoElectricityCalculationResult on EnergoElectricityCalculationResult {
  resultItems {
    ...EnergoElectricityResultItem
  }
  common {
    ...EnergoElectricityCalculationResultCommon
  }
  bestGuaranteed {
    ...EnergoElectricityResultItem
  }
  bestLengthOfFixation {
    ...EnergoElectricityResultItem
  }
  bestNoFixation {
    ...EnergoElectricityResultItem
  }
}
    ${EnergoElectricityResultItemFragmentDoc}
${EnergoElectricityCalculationResultCommonFragmentDoc}`;
export const EnergoGasCalculationResultCommonFragmentDoc = gql`
    fragment EnergoGasCalculationResultCommon on EnergoGasCalculationResultCommon {
  priceCurrentAnnually
  priceCurrentMonthly
  consumption
}
    `;
export const EnergoGasCalculationResultFragmentDoc = gql`
    fragment EnergoGasCalculationResult on EnergoGasCalculationResult {
  resultItems {
    ...EnergoGasResultItem
  }
  common {
    ...EnergoGasCalculationResultCommon
  }
  bestGuaranteed {
    ...EnergoGasResultItem
  }
  bestLengthOfFixation {
    ...EnergoGasResultItem
  }
  bestNoFixation {
    ...EnergoGasResultItem
  }
}
    ${EnergoGasResultItemFragmentDoc}
${EnergoGasCalculationResultCommonFragmentDoc}`;
export const EnergoCalculationResultFragmentDoc = gql`
    fragment EnergoCalculationResult on EnergoCalculationResult {
  id
  calculation {
    ...EnergoCalculation
  }
  electricityResult {
    ...EnergoElectricityCalculationResult
  }
  gasResult {
    ...EnergoGasCalculationResult
  }
  hasEnergoOffers
}
    ${EnergoCalculationFragmentDoc}
${EnergoElectricityCalculationResultFragmentDoc}
${EnergoGasCalculationResultFragmentDoc}`;
export const EnergoBreakerCodebookFragmentDoc = gql`
    fragment EnergoBreakerCodebook on EnergoBreaker {
  id
  label: name
}
    `;
export const EnergoDistributionRateCodebookFragmentDoc = gql`
    fragment EnergoDistributionRateCodebook on EnergoDistributionRate {
  id
  label: name
  description
  lowTariff
}
    `;
export const EnergoElectricityLeadFragmentDoc = gql`
    fragment EnergoElectricityLead on EnergoElectricityLead {
  id
  lead {
    ...Lead
  }
  calculation {
    ...EnergoCalculation
  }
  resultItem {
    ...EnergoElectricityResultItem
  }
  userResultItem {
    ...EnergoElectricityResultItem
  }
  partner {
    ...EnergoElectricityPartner
  }
  breaker {
    ...EnergoBreakerCodebook
  }
  distributionRate {
    ...EnergoDistributionRateCodebook
  }
  yearlyPayment
  yearlyConsumption
  yearlyConsumptionLowTariff
  currentProductId
  currentProductName
}
    ${LeadFragmentDoc}
${EnergoCalculationFragmentDoc}
${EnergoElectricityResultItemFragmentDoc}
${EnergoElectricityPartnerFragmentDoc}
${EnergoBreakerCodebookFragmentDoc}
${EnergoDistributionRateCodebookFragmentDoc}`;
export const EnergoGasLeadFragmentDoc = gql`
    fragment EnergoGasLead on EnergoGasLead {
  id
  lead {
    ...Lead
  }
  calculation {
    ...EnergoCalculation
  }
  resultItem {
    ...EnergoGasResultItem
  }
  userResultItem {
    ...EnergoGasResultItem
  }
  partner {
    ...EnergoGasPartner
  }
  yearlyPayment
  yearlyConsumption
  currentProductId
  currentProductName
}
    ${LeadFragmentDoc}
${EnergoCalculationFragmentDoc}
${EnergoGasResultItemFragmentDoc}
${EnergoGasPartnerFragmentDoc}`;
export const EnergoKalkulatorEnergoElectricityProductFragmentDoc = gql`
    fragment EnergoKalkulatorEnergoElectricityProduct on EnergoKalkulatorEnergoElectricityProduct {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  isActiveForSelectCurrentEstimate
  isActiveForSelectCurrentExact
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  partner {
    ...EnergoElectricityPartner
  }
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
  createdAt
  updatedAt
  adminLastEdit
}
    ${EnergoElectricityPartnerFragmentDoc}`;
export const EnergoKalkulatorEnergoElectricityProductDetailFragmentDoc = gql`
    fragment EnergoKalkulatorEnergoElectricityProductDetail on EnergoKalkulatorEnergoElectricityProductDetail {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  isActiveForSelectCurrentEstimate
  isActiveForSelectCurrentExact
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  partner {
    label
    value
  }
  partnerId
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
  createdAt
  updatedAt
  adminLastEdit
}
    `;
export const EnergoKalkulatorEnergoGasProductFragmentDoc = gql`
    fragment EnergoKalkulatorEnergoGasProduct on EnergoKalkulatorEnergoGasProduct {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  isActiveForSelectCurrentEstimate
  isActiveForSelectCurrentExact
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  partner {
    ...EnergoGasPartner
  }
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
  createdAt
  updatedAt
  adminLastEdit
}
    ${EnergoGasPartnerFragmentDoc}`;
export const EnergoKalkulatorEnergoGasProductDetailFragmentDoc = gql`
    fragment EnergoKalkulatorEnergoGasProductDetail on EnergoKalkulatorEnergoGasProductDetail {
  id
  name
  shortName
  gift
  bonus
  descriptionMD
  partnerTransition
  isActiveForPurchase
  isActive
  isActiveForSelectCurrentEstimate
  isActiveForSelectCurrentExact
  productInfoListBasic
  productInfoListExtended
  lengthOfFixation
  lengthOfCommitment
  followingProductId
  partner {
    label
    value
  }
  partnerId
  fixedEndOfCommitment
  fixedEndOfFixation
  private
  preferredSale
  availableForSale
  label
  labelTooltip
  createdAt
  updatedAt
  adminLastEdit
}
    `;
export const EnergoElectricityPartnerCodebookFragmentDoc = gql`
    fragment EnergoElectricityPartnerCodebook on EnergoElectricityPartnerCodebook {
  id
  isActive
  label: name
  seoName
  logoUrl
  invoiceHelpDistributionRateMD
  invoiceHelpDistributionRateImage {
    ...File
  }
  invoiceHelpInvoicedPaymentMD
  invoiceHelpInvoicedPaymentImage {
    ...File
  }
  invoiceHelpHighTariffMD
  invoiceHelpHighTariffImage {
    ...File
  }
  invoiceHelpLowTariffMD
  invoiceHelpLowTariffImage {
    ...File
  }
  invoiceHelpBreakerMD
  invoiceHelpBreakerImage {
    ...File
  }
  invoiceHelpCurrentProductMD
  invoiceHelpCurrentProductImage {
    ...File
  }
  cooperationMethod
  termsOfServicePreview
  estimateDefaultProductId
  createdAt
  updatedAt
  adminLastEdit
}
    ${FileFragmentDoc}`;
export const EnergoGasPartnerCodebookFragmentDoc = gql`
    fragment EnergoGasPartnerCodebook on EnergoGasPartnerCodebook {
  id
  isActive
  label: name
  seoName
  logoUrl
  invoiceHelpYearlyConsumptionMD
  invoiceHelpYearlyConsumptionImage {
    ...File
  }
  invoiceHelpInvoicedPaymentMD
  invoiceHelpInvoicedPaymentImage {
    ...File
  }
  invoiceHelpCurrentProductMD
  invoiceHelpCurrentProductImage {
    ...File
  }
  cooperationMethod
  termsOfServicePreview
  estimateDefaultProductId
  createdAt
  updatedAt
  adminLastEdit
}
    ${FileFragmentDoc}`;
export const EnergoFormCodebooksFragmentDoc = gql`
    fragment EnergoFormCodebooks on EnergoCodebooks {
  breaker {
    ...EnergoBreakerCodebook
  }
  partner {
    ...EnergoElectricityPartnerCodebook
  }
  gasPartner {
    ...EnergoGasPartnerCodebook
  }
  distributionRate {
    ...EnergoDistributionRateCodebook
  }
  personsCountRange {
    ...EnergoPersonsCountRangeCodebook
  }
  floorAreaRange {
    ...EnergoFloorAreaRangeCodebook
  }
  isolation {
    ...EnergoIsolationCodebook
  }
}
    ${EnergoBreakerCodebookFragmentDoc}
${EnergoElectricityPartnerCodebookFragmentDoc}
${EnergoGasPartnerCodebookFragmentDoc}
${EnergoDistributionRateCodebookFragmentDoc}
${EnergoPersonsCountRangeCodebookFragmentDoc}
${EnergoFloorAreaRangeCodebookFragmentDoc}
${EnergoIsolationCodebookFragmentDoc}`;
export const EnergoStandaloneInvoiceFragmentDoc = gql`
    fragment EnergoStandaloneInvoice on EnergoStandaloneInvoice {
  id
  uuid
}
    `;
export const EnergoElectricityPartnerDetailFragmentDoc = gql`
    fragment EnergoElectricityPartnerDetail on EnergoElectricityPartnerDetail {
  id
  name
  seoName
  logoUrl
  logoSquareUrl
  rating {
    ...NewRating
  }
  descriptionMD
  cooperationMethod
  termsOfServicePreview
  companyAddress
  contactEmail
  tollFreePhone
  latitude
  longitude
  web
  urlVop
  urlPricelist
  longArticleMD
  isActive
  energoPartnerId
  tollFreePhone
  title
  titleSuffix
  metaTitle
  metaDescription
}
    ${NewRatingFragmentDoc}`;
export const EnergoGasPartnerDetailFragmentDoc = gql`
    fragment EnergoGasPartnerDetail on EnergoGasPartnerDetail {
  id
  name
  seoName
  logoUrl
  logoSquareUrl
  rating {
    ...NewRating
  }
  descriptionMD
  cooperationMethod
  termsOfServicePreview
  companyAddress
  contactEmail
  tollFreePhone
  latitude
  longitude
  web
  urlVop
  urlPricelist
  longArticleMD
  isActive
  tollFreePhone
  energoPartnerId
  title
  titleSuffix
  metaTitle
  metaDescription
}
    ${NewRatingFragmentDoc}`;
export const EnergoPartnerFragmentDoc = gql`
    fragment EnergoPartner on EnergoPartner {
  id
  name
  seoName
  descriptionMD
  partnerElectro {
    ...EnergoElectricityPartnerDetail
  }
  partnerGas {
    ...EnergoGasPartnerDetail
  }
  title
  metaDescription
  metaTitle
}
    ${EnergoElectricityPartnerDetailFragmentDoc}
${EnergoGasPartnerDetailFragmentDoc}`;
export const EnergoElectricityPartnerPreviewFragmentDoc = gql`
    fragment EnergoElectricityPartnerPreview on EnergoElectricityPartnerPreview {
  id
  name
  energoPartnerId
  seoName
  logoUrl
  logoSquareUrl
  cooperationMethod
  companyAddress
  contactEmail
  tollFreePhone
  longitude
  latitude
  web
  urlVop
  urlPricelist
  longArticleMD
  active
  estimateDefaultProductId
}
    `;
export const EnergoElectricityProductPreviewFragmentDoc = gql`
    fragment EnergoElectricityProductPreview on EnergoElectricityProductPreview {
  partner {
    ...EnergoElectricityPartnerPreview
  }
  id
  name
  isActive
  isActiveForPurchase
  activeForNewCustomers
  activeForCurrentCustomers
  activeForSelectCurrentExact
  activeForSelectCurrentEstimate
  private
  preferredSale
  availableForSale
}
    ${EnergoElectricityPartnerPreviewFragmentDoc}`;
export const EnergoElectricityPriceProductPreviewItemFragmentDoc = gql`
    fragment EnergoElectricityPriceProductPreviewItem on EnergoElectricityPriceProductPreviewItem {
  id
  priceMonthly
  priceMWhHighTariff
  priceMWhLowTariff
  distributionRateId
  distributorId
  product {
    ...EnergoElectricityProductPreview
  }
}
    ${EnergoElectricityProductPreviewFragmentDoc}`;
export const EnergoGasPartnerPreviewFragmentDoc = gql`
    fragment EnergoGasPartnerPreview on EnergoGasPartnerPreview {
  id
  name
  energoPartnerId
  seoName
  logoUrl
  logoSquareUrl
  cooperationMethod
  companyAddress
  contactEmail
  tollFreePhone
  longitude
  latitude
  web
  urlVop
  urlPricelist
  longArticleMD
  active
  estimateDefaultProductId
}
    `;
export const EnergoGasProductPreviewFragmentDoc = gql`
    fragment EnergoGasProductPreview on EnergoGasProductPreview {
  partner {
    ...EnergoGasPartnerPreview
  }
  id
  name
  isActive
  isActiveForPurchase
  activeForNewCustomers
  activeForCurrentCustomers
  activeForSelectCurrentExact
  activeForSelectCurrentEstimate
  private
  preferredSale
  availableForSale
}
    ${EnergoGasPartnerPreviewFragmentDoc}`;
export const EnergoGasPriceProductPreviewItemFragmentDoc = gql`
    fragment EnergoGasPriceProductPreviewItem on EnergoGasPriceProductPreviewItem {
  id
  pricePerM3
  priceMonthly
  priceMWh
  odberovePasmoId
  product {
    ...EnergoGasProductPreview
  }
}
    ${EnergoGasProductPreviewFragmentDoc}`;